<template>
  <div
    class="relative rounded-lg px-2 py-1.5 border-b border-gray-100 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex flex-col text-left"
  >
    <div class="flex justify-between items-center gap-2">
      <span class="flex gap-2 items-center">
        <fw-icon-arrow-right v-if="!item.balance" class="w-5 h-5 text-gray-400 flex-shrink-0" />
        <fw-icon-arrow-up v-else-if="!item.balance.startsWith('-')" class="w-5 h-5 text-primary flex-shrink-0" />
        <fw-icon-arrow-down v-else class="w-5 h-5 text-red-600 flex-shrink-0" />
        <span>{{ item.balance_day }}/{{ month }}/{{ year }}</span>
      </span>
      <span
        class="font-medium"
        :class="{
          'text-gray-600': !item.balance || !item.balance.startsWith('-'),
          'text-red-600': item.balance && item.balance?.startsWith('-')
        }"
        >{{ item.balance.replace(/\s/g, '') }} h</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    year: {
      type: [Number, String]
    },
    month: {
      type: [Number, String]
    }
  },

  methods: {}
}
</script>
